import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import BreadcrumbGroup, {
  BreadcrumbGroupProps,
} from '@cloudscape-design/components/breadcrumb-group';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useAxiosInterceptors } from '../services/axiosInstance';

const HOME = { text: 'Escape Products', href: '/' };

export function Breadcrumbs({ items }: { items: BreadcrumbGroupProps.Item[] }) {
  const navigate = useNavigate();

  return (
    <BreadcrumbGroup
      items={[HOME, ...items]}
      onClick={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  const sideItems: SideNavigationProps.Item[] = [];

  const infoItems: SideNavigationProps.Item[] = [];

  infoItems.push({ type: 'link', text: 'Home', href: '/' });
  infoItems.push({ type: 'link', text: 'Services', href: '/services' });
  if (infoItems.length > 0) {
    if (sideItems.length > 0) {
      sideItems.push({ type: 'divider' });
    }
    sideItems.push({
      type: 'section',
      text: 'Information',
      items: infoItems,
    });
  }

  const licenseItems: SideNavigationProps.Item[] = [];
  if (isAuthenticated) {
    licenseItems.push({ type: 'link', text: 'Licenses', href: '/licenses' });
    licenseItems.push({ type: 'link', text: 'Users', href: '/users' });
    if (licenseItems.length > 0) {
      if (sideItems.length > 0) {
        sideItems.push({ type: 'divider' });
      }
      sideItems.push({
        type: 'section',
        text: 'Management',
        items: licenseItems,
      });
    }
  }

  return (
    <SideNavigation
      activeHref={location.pathname}
      className="side-navigation"
      items={sideItems}
      onFollow={(event) => {
        if (event.detail.external) {
          return;
        }
        event.preventDefault();
        navigate(event.detail.href);
      }}
    />
  );
}

export function LocalTopNavigation() {
  useAxiosInterceptors();

  const navigate = useNavigate();
  const { user, loginWithRedirect, logout } = useAuth0();

  return (
    <TopNavigation
      className="top-navigation"
      identity={{
        title: 'Escape Products',
        href: '/',
        logo: {
          src: 'https://cdn.aiescape.io/logo-assistant-fit.png',
          alt: 'Escape Products',
        },
        onFollow: (event) => {
          event.preventDefault();
          navigate('/');
        },
      }}
      utilities={
        user
          ? [
              {
                type: 'menu-dropdown',
                text: user.email,
                iconName: 'user-profile',
                onItemClick: (event) => {
                  if (event.detail.external) {
                    return;
                  }
                  event.preventDefault();

                  const href = event.detail.href;

                  if (href) {
                    navigate(href);
                  }

                  if (event.detail.id === 'logout') {
                    logout({ logoutParams: { returnTo: window.location.origin } });
                  } else if (event.detail.id === 'billing') {
                    window.location.href = 'https://billing.aiescape.io/p/login/aEU3eo1lUbdB8DKbII';
                  }
                },
                items: [
                  {
                    id: 'billing',
                    itemType: 'action',
                    text: 'Billing',
                    iconName: 'folder',
                  },
                  {
                    id: 'logout',
                    itemType: 'action',
                    text: 'Logout',
                    iconName: 'redo',
                  },
                ],
              },
            ]
          : [
              {
                type: 'button',
                text: 'Login',
                onClick: (event) => {
                  event.preventDefault();
                  loginWithRedirect();
                },
              },
            ]
      }
    />
  );
}
