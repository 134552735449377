import axiosInstance from './axiosInstance';

import { ServiceDetailRead, ServiceRead } from './types';

export async function getServices() {
  const response = await axiosInstance.get(`/services`);
  return response.data as ServiceRead[];
}

export async function getService(serviceId: string) {
  const response = await axiosInstance.get(`/services/${serviceId}`);
  return response.data as ServiceDetailRead;
}
