import axiosInstance from './axiosInstance';

import { LicenseAssign, LicenseUserRead, UserCreate, UserRead } from './types';

export async function getUserLicenses(userId: string) {
  const response = await axiosInstance.get(`/users/${userId}/licenses`);
  return response.data as LicenseUserRead[];
}

export async function getUserLicensesPurchase(userId: string, session_id: string) {
  const params = {
    session_id,
  };
  const response = await axiosInstance.get(`/users/${userId}/licenses/purchase`, {
    params,
  });

  return response.status;
}

export async function assignUserLicense(userId: string, licenseId: string, data: LicenseAssign) {
  const response = await axiosInstance.post(`/users/${userId}/licenses/${licenseId}`, data);
  return response.data as LicenseUserRead;
}

export async function createUser(data: UserCreate) {
  const response = await axiosInstance.post(`/users`, data);
  return response.data as UserRead;
}
