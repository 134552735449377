import { useEffect, useState } from 'react';
import {
  LicensePurchase,
  LicensePurchaseResponse,
  ProductRead,
  UserCreate,
  UserRead,
} from '../services/types';
import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Header,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { createUser } from '../services/license';
import { ErrorMessage } from './ErrorMessage';

export type UserCreateModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  onComplete?: (item: UserRead) => void;
};

export function UserCreateModal(props: UserCreateModalProps) {
  const { open, setOpen, onComplete } = props;

  const { user } = useAuth0();
  const navigate = useNavigate();

  const domain = user && user.email ? user?.email.split('@')[1] : undefined;

  const [email, setEmail] = useState<string>('');

  const close = () => {
    setOpen(false);
    setEmail('');
    create.reset();
  };

  const create = useMutation({
    mutationFn: createUser,
    onSuccess: (data: UserRead) => {
      if (data) {
        if (onComplete) {
          onComplete(data);
        }
        close();
      }
    },
  });

  const handleCreate = () => {
    if (create.isPending) {
      return;
    }
    if (!email) {
      return;
    }

    const data: UserCreate = {
      email,
    };
    create.mutate(data);
  };

  return (
    <Modal
      onDismiss={close}
      visible={open}
      closeAriaLabel="Close"
      header={
        <Header
          variant="h2"
          description={`The email must utilize the same organization domain name: ${domain}`}
        >
          Add a user to your organization
        </Header>
      }
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={close}>
              Cancel
            </Button>
            <Button
              loading={create.isPending}
              variant="primary"
              onClick={handleCreate}
              disabled={!email}
            >
              Add
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <FormField description="The user's email address" label="Email">
          <Input
            placeholder={`someone@${domain}...`}
            inputMode="email"
            type="email"
            value={email}
            onChange={({ detail }) => {
              setEmail(detail.value.trim());
            }}
            onKeyDown={({ detail }) => {
              if (detail.key === 'Enter') {
                handleCreate();
              }
            }}
          />
        </FormField>
        {create.error && <ErrorMessage loading={create.isPending} error={create.error} />}
      </SpaceBetween>
    </Modal>
  );
}
