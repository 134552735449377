import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@cloudscape-design/components';
import { useEffect, useState } from 'react';
import { authParams } from '../context/AuthContext';

export type AuthRequiredProps = {
  children: React.ReactNode;
};

export function AuthRequired(props: AuthRequiredProps) {
  const { loginWithRedirect, isLoading, isAuthenticated, error } = useAuth0();
  const [authError, setAuthError] = useState<string>();

  useEffect(() => {
    if (error) {
      // Capture and display the error
      setAuthError(error.message);
      console.error(error);
    } else if (!isAuthenticated && !isLoading) {
      const params = new URLSearchParams(window.location.search);
      const organization = params.get('organization');
      const invitation = params.get('invitation');
      loginWithRedirect({
        authorizationParams:
          organization && invitation
            ? {
                ...authParams,
                organization,
                invitation,
              }
            : undefined,
      }).catch((err) => {
        // Handle login errors
        setAuthError(err.error_description || 'Login failed. Please try again.');
      });
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, error]);

  if (isLoading) {
    return (
      <div>
        <Spinner size="large" />
      </div>
    );
  }

  if (authError) {
    // TODO - Add cleaner error to UI
    return (
      <div>
        <h1>Authentication Error</h1>
        <p>Error logging in: {authError}</p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return (
      <div>
        <Spinner size="large" />
      </div>
    );
  }

  return <>{props.children}</>;
}
