import {
  Container,
  Box,
  Header,
  Button,
  Grid,
  SpaceBetween,
  Table,
  StatusIndicator,
  Pagination,
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { useNavigate } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserLicenses } from '../services/license';
import { ResourceError } from '../components/Loading';
import { LicenseUserRead } from '../services/types';
import { PurchaseModal } from '../components/PurchaseModal';
import { AssignModal } from '../components/AssignModal';

export default function View() {
  const navigate = useNavigate();
  const resourceName = 'Licenses';
  const endpointName = 'licenses';

  const [createOpen, setCreateOpen] = useState(false);
  const [assignOpen, setAssignOpen] = useState(false);

  const [selected, setSelected] = useState<LicenseUserRead[]>();

  const { user } = useAuth0();

  const pageSize = 10;

  const query = useQuery({
    queryKey: [user?.sub, endpointName],
    enabled: !!user?.sub,
    queryFn: async () => (user && user.sub ? await getUserLicenses(user.sub) : undefined),
    // staleTime: 3_600_000, // 60 minutes
  });

  const { items, paginationProps } = useCollection(query.data ?? [], {
    pagination: { pageSize },
  });

  return (
    <ViewLayout breadcrumbs={[{ text: 'Licenses', href: '' }]} contentType="table">
      <Table
        {...paginationProps}
        pagination={<Pagination {...paginationProps} disabled={query.isFetching} />}
        header={
          <Header
            variant="awsui-h1-sticky"
            description="Product Licenses owned by you"
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  variant="primary"
                  onClick={() => {
                    setCreateOpen(true);
                  }}
                >
                  Purchase More
                </Button>
                <Button
                  variant="normal"
                  disabled={!selected?.length}
                  onClick={() => {
                    setAssignOpen(true);
                  }}
                >
                  Assign
                </Button>
                <PurchaseModal open={createOpen} setOpen={setCreateOpen} />
                <AssignModal
                  open={assignOpen}
                  setOpen={setAssignOpen}
                  onComplete={() => {
                    query.refetch();
                    setSelected(undefined);
                  }}
                  license={selected && selected.length === 1 ? selected[0] : undefined}
                />
                {/* TODO add delete button to remove licenses & cancel stripe subscription */}
              </SpaceBetween>
            }
            counter={query.data?.length ? `(${query.data.length})` : undefined}
          >
            {resourceName}
          </Header>
        }
        items={items}
        loading={query.isFetching}
        loadingText={`Loading ${resourceName}...`}
        variant="full-page"
        stickyHeader
        selectedItems={selected}
        onSelectionChange={({ detail }) => {
          setSelected(detail.selectedItems);
        }}
        selectionType="single"
        empty={
          <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
            {query.error ? (
              <ResourceError
                error={query.error}
                refetch={query.refetch}
                resourceName={resourceName}
              />
            ) : (
              <SpaceBetween size="xxs">
                <div>
                  <b>No {resourceName.toLowerCase()}</b>
                  <Box color="inherit" variant="p">
                    No {resourceName.toLowerCase()} found.
                  </Box>
                </div>
              </SpaceBetween>
            )}
          </Box>
        }
        columnDefinitions={[
          {
            id: 'product',
            header: 'Product',
            // TODO link to product page
            cell: (item) => item.product.name,
          },
          {
            id: 'assigned_to',
            header: 'Assigned To',
            // TODO link to user page
            cell: (item) =>
              item.assigned_user
                ? `${item.assigned_user.email}${
                    item.assigned_user.name ? ` (${item.assigned_user.name})` : ''
                  }`
                : '-',
          },
          // {
          //   id: 'created',
          //   header: 'Created',
          //   cell: (item) => new Date(item.created_at + 'Z').toLocaleString(),
          // },
          {
            id: 'status',
            header: 'Status',
            // TODO color code
            cell: (item) => (
              <StatusIndicator
                type={
                  item.status === 'pending'
                    ? 'pending'
                    : item.status === 'active'
                      ? 'success'
                      : 'error'
                }
              >
                {item.status.charAt(0).toUpperCase() + item.status.slice(1)}
              </StatusIndicator>
            ),
          },
        ]}
      />
    </ViewLayout>
  );
}
