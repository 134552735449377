import {
  AppLayout,
  ContentLayout,
  BreadcrumbGroupProps,
  AppLayoutProps,
} from '@cloudscape-design/components';
import { useContext } from 'react';
import { NavigationContext } from '../context/Navigation';
import { Breadcrumbs, LocalNavigation } from './Navigation';

export type ViewLayoutProps = {
  header?: React.ReactNode;
  secondaryHeader?: React.ReactNode;
  breadcrumbs?: BreadcrumbGroupProps.Item[];
  children: React.ReactNode;
  contentType?: AppLayoutProps.ContentType;
  headerVariant?: 'default' | 'high-contrast';
};

export function ViewLayout(props: ViewLayoutProps) {
  const { navigationSize, navigationOpen, setNavigationOpen } = useContext(NavigationContext);

  return (
    <AppLayout
      breadcrumbs={props.breadcrumbs ? <Breadcrumbs items={props.breadcrumbs} /> : undefined}
      className="app-layout"
      headerVariant={props.headerVariant}
      content={
        <ContentLayout
          header={props.header}
          secondaryHeader={props.secondaryHeader}
          headerVariant={props.headerVariant}
        >
          {props.children}
        </ContentLayout>
      }
      contentType={props.contentType}
      headerSelector=".top-navigation"
      navigation={<LocalNavigation />}
      navigationOpen={navigationOpen}
      navigationWidth={navigationSize}
      toolsHide
      onNavigationChange={(event) => setNavigationOpen(event.detail.open)}
    />
  );
}
