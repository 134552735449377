import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';

import 'swiper/swiper-bundle.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useWindowDimensions from '../services/window';

export type ScrollingListProps = {
  children: React.ReactNode[];
};

export function ScrollingList(props: ScrollingListProps) {
  const { width } = useWindowDimensions();
  const modules = [Pagination];
  const navigation = width > 768;
  if (navigation) {
    modules.push(Navigation);
  }
  return (
    <Swiper
      modules={modules}
      pagination={{ clickable: true }}
      slidesPerView={1}
      spaceBetween={50}
      navigation={navigation}
    >
      {props.children.map((client, index) => (
        <SwiperSlide key={`swiper-${index}-slide`}>
          <div
            style={{
              marginLeft: navigation ? '50px' : '0',
              marginRight: navigation ? '50px' : '0',
              marginBottom: '30px',
              marginTop: '30px',
            }}
          >
            {client}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
