import { createContext } from 'react';

export type NavigationContextType = {
  navigationOpen: boolean;
  setNavigationOpen: (value: boolean) => void;
  navigationSize: number;
};

export const NavigationContext = createContext<NavigationContextType>({
  navigationOpen: true,
  navigationSize: 200,
  setNavigationOpen: (_value: boolean) => {
    return;
  },
});
