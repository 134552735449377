import { useEffect, useState } from 'react';
import { LicensePurchase, LicensePurchaseResponse, ProductRead } from '../services/types';
import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Input,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getProducts, purchaseProduct } from '../services/product';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { ErrorMessage } from './ErrorMessage';

export type PurchaseModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  product?: ProductRead;
};

export function PurchaseModal(props: PurchaseModalProps) {
  const { open, setOpen, product } = props;

  const { user } = useAuth0();
  const navigate = useNavigate();

  const [selectedProduct, setSelectedProduct] = useState<SelectProps.Option | null>(null);
  const [quantity, setQuantity] = useState<number>(1);

  useEffect(() => {
    setSelectedProduct(
      product
        ? {
            label: product.name,
            value: product.uuid,
          }
        : null,
    );
  }, [product]);

  const create = useMutation({
    mutationFn: async (data: LicensePurchase) =>
      user?.sub ? await purchaseProduct(user.sub, data) : undefined,
    onSuccess: (data: LicensePurchaseResponse | undefined) => {
      if (data) {
        // close();
        // navigate(data.session_url);
        window.location.href = data.session_url;
      }
    },
  });

  const close = () => {
    setOpen(false);
    setSelectedProduct(
      product
        ? {
            label: product.name,
            value: product.uuid,
          }
        : null,
    );
    create.reset();
  };

  const productQuery = useQuery({
    queryKey: ['products'],
    queryFn: getProducts,
    staleTime: 3_600_000, // 60 minutes
  });

  const handleCreate = () => {
    if (create.isPending) {
      return;
    }
    if (!user?.sub || !selectedProduct || !selectedProduct.value) {
      // TODO error
      return;
    }

    const data: LicensePurchase = {
      product_uuid: selectedProduct.value,
      quantity,
    };
    create.mutate(data);
  };

  return (
    <Modal
      onDismiss={close}
      visible={open}
      closeAriaLabel="Close"
      header="Purchase new product licenses"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={close}>
              Cancel
            </Button>
            <Button
              loading={create.isPending}
              variant="primary"
              onClick={handleCreate}
              disabled={!selectedProduct}
            >
              Purchase
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <FormField description="The product license to purchase" label="Product">
          <Select
            selectedOption={selectedProduct}
            onChange={({ detail }) => setSelectedProduct(detail.selectedOption)}
            options={
              productQuery.data?.map((item) => ({
                label: item.name,
                value: item.uuid,
                // tags: [item.service.name],
              })) ?? []
            }
            empty="No products found"
            loadingText="Loading products..."
            filteringType="auto"
            statusType={
              productQuery.isError
                ? 'error'
                : productQuery.isLoading || productQuery.isPending || productQuery.isFetching
                  ? 'loading'
                  : undefined
            }
          />
        </FormField>
        <FormField
          description="The number of licenses to purchase. This corresponds to the number of users that can access the product, as each user requires a license."
          label="Quantity"
        >
          <Input
            type="number"
            inputMode="numeric"
            value={`${quantity}`}
            onChange={({ detail }) => {
              const value = parseInt(detail.value);
              if (isNaN(value) || value < 1) {
                return;
              }
              setQuantity(value);
            }}
          />
        </FormField>
        {create.error && <ErrorMessage loading={create.isPending} error={create.error} />}
      </SpaceBetween>
    </Modal>
  );
}
