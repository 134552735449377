import { Container, Box, Header, Button, Grid, SpaceBetween } from '@cloudscape-design/components';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import { useEffect, useState } from 'react';
import { useInterval, useTimeout } from 'react-use';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserLicensesPurchase } from '../services/license';
import { useQuery } from '@tanstack/react-query';
import { LoadingOrError } from '../components/Loading';

export default function View() {
  const navigate = useNavigate();
  const [active, setActive] = useState<boolean>(false);

  const [params] = useSearchParams();
  const session_id = params.get('session_id');

  const { user } = useAuth0();

  // polling until subscription is active

  const query = useQuery({
    queryKey: [session_id],
    enabled: !!(user?.sub && session_id),
    queryFn: async () =>
      user?.sub && session_id ? await getUserLicensesPurchase(user.sub, session_id) : undefined,
    // staleTime: 3_600_000, // 60 minutes
  });

  useEffect(() => {
    if (query.data === 200) {
      setActive(false);
      navigate('/licenses');
    }
  }, [query.data]);

  // WAIT on STRIPE to COMPLETE:
  // GET http://localhost:80/users/waad|R2osFmJd7qUjV1RtNjNpjB8u5i6Zt48_gSQKSWQvJWo/licenses/purchase/{session_id}
  // 202 means need to keep polling
  // 200 means paid and ready to refresh
  // TODO otherwise certain error means payment failed */}
  useEffect(() => {
    const interval = setInterval(async () => {
      if (active) {
        query.refetch();
      } else {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [session_id, active]);

  return (
    <ViewLayout
      breadcrumbs={[
        { text: 'Licenses', href: '/licenses' },
        { text: 'Purchased', href: '' },
      ]}
      header={<Header variant="h1">License Purchased</Header>}
      contentType="table"
    >
      <LoadingOrError loading={query.isFetching} error={query.error} retry={query.refetch} />
    </ViewLayout>
  );
}
