import React from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const redirectUri =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://products.aiescape.io';

const domain = 'auth.aiescape.io';
const clientId = '1bhYhuTDR4XGr7h2hOVfaX3dNgIHCwgz';
const audience = 'https://products-api.aiescape.io';

export const authParams = {
  audience,
  redirect_uri: redirectUri,
  scope: 'openid profile email offline_access',
  // organization: '',
  // prompt: "consent", // This will force the consent screen to show every time, good for testing
};

export type Auth0ProviderWithHistoryProps = {
  children: React.ReactNode;
};

function Auth0ProviderWithHistory(props: Auth0ProviderWithHistoryProps) {
  const { children } = props;
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      authorizationParams={authParams}
      cacheLocation="localstorage"
      clientId={clientId}
      domain={domain}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}

export default Auth0ProviderWithHistory;
