import {
  Container,
  Box,
  Header,
  Button,
  Grid,
  SpaceBetween,
  Cards,
  Link,
  ColumnLayout,
  Table,
  Textarea,
  TextContent,
} from '@cloudscape-design/components';

import { useNavigate, useParams } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import { getService } from '../services/services';
import { useQuery } from '@tanstack/react-query';
import { LoadingOrError, ResourceError } from '../components/Loading';
import { ProductRead } from '../services/types';
import { useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PurchaseModal } from '../components/PurchaseModal';
import useWindowDimensions from '../services/window';
import { ScrollingList } from '../components/ScrollingList';

export default function View() {
  const navigate = useNavigate();
  const endpointName = 'services';

  const { width } = useWindowDimensions();
  const params = useParams();
  const serviceId = params.serviceId;

  const [createOpen, setCreateOpen] = useState(false);

  const [selected, setSelected] = useState<ProductRead[]>();

  const { isAuthenticated, loginWithRedirect } = useAuth0();

  const query = useQuery({
    queryKey: [endpointName, serviceId],
    queryFn: async () => (serviceId ? await getService(serviceId) : undefined),
    staleTime: 3_600_000,
  });

  const products = useMemo(() => {
    const sortedProducts = query.data?.products ? [...query.data.products] : [];
    sortedProducts.sort((a, b) => a.order - b.order);
    return sortedProducts;
  }, [query.data]);

  const mediaChildren: React.ReactNode[] = [];
  if (query.data?.video_url) {
    mediaChildren.push(
      <div className="video-container">
        <iframe
          allow="autoplay; encrypted-media;"
          className="video"
          frameBorder="0"
          allowFullScreen
          src={query.data.video_url}
          title="YouTube video player"
        />
      </div>,
    );
  }
  if (query.data && query.data.images.length > 0) {
    query.data.images.forEach((image) => {
      mediaChildren.push(
        <img
          key={image.image_url}
          alt={image.caption}
          src={image.image_url}
          style={{
            width: '100%',
            height: 'auto',
            borderRadius: '16px',
          }}
        />,
      );
    });
  }

  return (
    <ViewLayout
      breadcrumbs={[
        { text: 'Services', href: '/services' },
        { text: query.data?.name ?? '', href: '' },
      ]}
      header={
        <Header
          variant="h1"
          description={
            query.data && (
              <Link href={query.data.url} target="_blank" fontSize="heading-s">
                {query.data.url}
              </Link>
            )
          }
        >
          {query.data?.name ?? ''}
        </Header>
      }
    >
      {!query.data ? (
        <LoadingOrError loading={query.isFetching} error={query.error} retry={query.refetch} />
      ) : (
        <SpaceBetween direction="vertical" size="m">
          <Container
            variant="default"
            media={{
              content: <img alt={query.data.name} src={query.data.image_url} />,
              position: width < 1024 ? 'top' : 'side',
              width: width < 1024 ? '100%' : '50%',
            }}
            header={
              <Header variant="h2" description={query.data.subtitle}>
                {query.data.title}
              </Header>
            }
            footer={
              query.data.contact_text && query.data.contact_email
                ? query.data.contact_text.split('\n').map((p, i) => (
                    <Box key={i} variant="p">
                      {p.trim()}{' '}
                      {i === query.data!.contact_text!.split('\n').length - 1 ? (
                        <Link
                          href={`mailto:${query.data!.contact_email}`}
                          fontSize="inherit"
                          variant="secondary"
                        >
                          {query.data!.contact_email}
                        </Link>
                      ) : (
                        ''
                      )}
                    </Box>
                  ))
                : undefined
            }
          >
            <SpaceBetween direction="vertical" size="m">
              <TextContent>
                {query.data.description && <Box variant="p">{query.data.description}</Box>}
                {query.data.content &&
                  query.data.content.split('\n').map((p, i) => (
                    <Box key={i} variant="p">
                      {p.trim()}
                    </Box>
                  ))}
              </TextContent>
            </SpaceBetween>
          </Container>
          {mediaChildren.length > 0 && (
            <SpaceBetween direction="vertical" size="xxxs">
              <Header
                variant="h2"
                description={`Check out these videos & images to learn more about ${query.data.name}.`}
              >
                Media
              </Header>
              <ScrollingList children={mediaChildren} />
            </SpaceBetween>
          )}
          {query.data.products.length > 0 ? (
            <Cards
              cardsPerRow={[
                {
                  cards: 1,
                  minWidth: 0,
                },
                {
                  cards: 2,
                  minWidth: 689,
                },
                {
                  cards: Math.min(3, query.data.products.length),
                  minWidth: 1024,
                },
              ]}
              cardDefinition={{
                header: (item) => (
                  <Header variant="h3" description={item.subtitle}>
                    {item.name}
                  </Header>
                ),
                sections: [
                  {
                    id: 'price',
                    content: (item) => (
                      <div>
                        {item.price ? (
                          <Box
                            variant="p"
                            fontSize="heading-m"
                            color="inherit"
                          >{`$${item.price.toLocaleString()} USD / month`}</Box>
                        ) : (
                          <Box variant="p" fontSize="heading-m" color="inherit">
                            Talk to us about pricing
                          </Box>
                        )}
                      </div>
                    ),
                  },
                  {
                    id: 'description',
                    content: (item) => (
                      <SpaceBetween direction="vertical" size="xs">
                        {item.description &&
                          item.description.split('\n').map((p, i) => (
                            <Box key={i} variant="p">
                              {p.trim()}
                            </Box>
                          ))}
                      </SpaceBetween>
                    ),
                  },
                  {
                    id: 'buy',
                    content: (item) => (
                      <div
                        style={{
                          textAlign: 'center',
                          borderTop: '1px solid lightgray',
                          borderBottom: '1px solid lightgray',
                          padding: '1rem',
                        }}
                      >
                        {item.price ? (
                          item.auth0_role ? (
                            <Button
                              variant="primary"
                              onClick={() => {
                                if (isAuthenticated) {
                                  setSelected([item]);
                                  setCreateOpen(true);
                                } else {
                                  loginWithRedirect();
                                }
                              }}
                            >
                              <Box variant="p" fontSize="heading-s" color="inherit">
                                Purchase
                              </Box>
                            </Button>
                          ) : (
                            <Button variant="primary" disabled>
                              <Box variant="p" fontSize="heading-s" color="inherit">
                                Coming Soon...
                              </Box>
                            </Button>
                          )
                        ) : (
                          <div
                            style={{
                              padding: '5px',
                            }}
                          >
                            <Box variant="p" fontSize="heading-m" color="inherit">
                              Contact us:{' '}
                              <Link
                                href="mailto:sales@aiescape.io"
                                fontSize="inherit"
                                variant="secondary"
                              >
                                sales@aiescape.io
                              </Link>
                            </Box>
                          </div>
                        )}
                      </div>
                    ),
                  },
                  {
                    id: 'features',
                    content: (item) => (
                      <SpaceBetween direction="vertical" size="xs">
                        {item.feature_description && (
                          <Box variant="p">{item.feature_description}</Box>
                        )}
                        <ul
                          style={{
                            listStyleType: 'disclosure-closed',
                            paddingLeft: '1rem',
                          }}
                        >
                          {item.features &&
                            item.features.split('\n').map((p, i) => (
                              <li
                                key={i}
                                style={{
                                  padding: '0.25rem',
                                }}
                              >
                                {p.trim()}
                              </li>
                              // <Box key={i} variant="p">{p.trim()}</Box>
                            ))}
                        </ul>
                      </SpaceBetween>
                    ),
                  },
                  {
                    id: 'discount',
                    content: (item) =>
                      item.discount_description ? (
                        <SpaceBetween direction="vertical" size="xs">
                          <div
                            style={{
                              borderTop: '1px solid lightgray',
                            }}
                          />
                          {item.discount_description.split('\n').map((p, i) => (
                            <Box key={i} variant="p">
                              {p.trim()}{' '}
                              {i === item.discount_description!.split('\n').length - 1 &&
                              item.discount_email ? (
                                <Link
                                  href={`mailto:${item.discount_email}`}
                                  fontSize="inherit"
                                  variant="secondary"
                                >
                                  {item.discount_email}
                                </Link>
                              ) : (
                                ''
                              )}
                            </Box>
                          ))}
                        </SpaceBetween>
                      ) : undefined,
                  },
                ],
              }}
              header={
                <Header
                  variant="h2"
                  description="Available license plans for this service. Licenses can be assigned to users after purchase."
                >
                  Plans
                </Header>
              }
              items={products}
              variant="full-page"
              stickyHeader
            />
          ) : undefined}
          <PurchaseModal
            open={createOpen}
            setOpen={setCreateOpen}
            product={selected && selected.length === 1 ? selected[0] : undefined}
          />
        </SpaceBetween>
      )}
    </ViewLayout>
  );
}
