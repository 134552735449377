import { useEffect, useState } from 'react';
import {
  LicenseAssign,
  LicensePurchase,
  LicensePurchaseResponse,
  LicenseRead,
  LicenseUserRead,
  ProductRead,
  UserRead,
} from '../services/types';
import {
  Box,
  Button,
  ColumnLayout,
  FormField,
  Modal,
  Select,
  SelectProps,
  SpaceBetween,
} from '@cloudscape-design/components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { assignUserLicense, getUserLicenses } from '../services/license';
import { getDomainUsers } from '../services/domains';
import { ErrorMessage } from './ErrorMessage';

export type AssignModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  assigned_user?: UserRead;
  license?: LicenseRead;
  onComplete?: (data: LicenseUserRead) => void;
};

export function AssignModal(props: AssignModalProps) {
  const { open, setOpen, assigned_user, license, onComplete } = props;

  const { user } = useAuth0();
  const navigate = useNavigate();

  const [selectedUser, setSelectedUser] = useState<SelectProps.Option | null>(null);
  const [selectedLicense, setSelectedLicense] = useState<SelectProps.Option | null>(null);

  const domain = user && user.email ? user?.email.split('@')[1] : undefined;

  const unassignOption = {
    label: '-',
    value: undefined,
  };

  useEffect(() => {
    setSelectedLicense(
      license
        ? {
            label: license.product.name,
            value: license.uuid,
            // TODO add more info here
            tags: license.assigned_user ? [license.assigned_user.email] : [],
          }
        : null,
    );
  }, [license]);

  useEffect(() => {
    setSelectedUser(
      assigned_user
        ? {
            label: `${assigned_user.email}${assigned_user.name ? ` (${assigned_user.name})` : ''}`,
            value: assigned_user.email,
          }
        : null,
    );
  }, [assigned_user]);

  const create = useMutation({
    mutationFn: async (x: { licenseId: string; data: LicenseAssign }) =>
      user?.sub ? await assignUserLicense(user.sub, x.licenseId, x.data) : undefined,
    onSuccess: (data: LicenseUserRead | undefined) => {
      if (data) {
        if (onComplete) {
          onComplete(data);
        }
        close();
      }
    },
  });

  const close = () => {
    setOpen(false);
    setSelectedUser(
      assigned_user
        ? {
            label: `${assigned_user.email}${assigned_user.name ? ` (${assigned_user.name})` : ''}`,
            value: assigned_user.email,
          }
        : null,
    );
    setSelectedLicense(
      license
        ? {
            label: license.product.name,
            value: license.uuid,
            // TODO add more info here
            tags: license.assigned_user ? [license.assigned_user.email] : [],
          }
        : null,
    );
    create.reset();
  };

  const userQuery = useQuery({
    queryKey: ['domains', domain, 'users'],
    enabled: !!user?.sub && open,
    queryFn: async () => (domain ? await getDomainUsers(domain) : undefined),
    // staleTime: 3_600_000, // 60 minutes
  });

  const licenseQuery = useQuery({
    queryKey: ['licenses'],
    enabled: !!user?.sub && open,
    queryFn: async () => (user?.sub ? await getUserLicenses(user?.sub) : undefined),
  });

  const handleCreate = () => {
    if (create.isPending) {
      return;
    }
    if (!user?.sub || !selectedUser || !selectedLicense || !selectedLicense.value) {
      // TODO error
      return;
    }

    const data: LicenseAssign = {
      email: selectedUser.value,
    };
    create.mutate({
      data,
      licenseId: selectedLicense.value,
    });
  };

  return (
    <Modal
      onDismiss={close}
      visible={open}
      closeAriaLabel="Close"
      header="Assign a product license to a user"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={close}>
              Cancel
            </Button>
            <Button
              loading={create.isPending}
              variant="primary"
              onClick={handleCreate}
              disabled={!selectedUser || !selectedLicense}
            >
              Assign
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <SpaceBetween size="m">
        <FormField description="The product license to assign" label="License">
          {/* TODO add link to purchase licenses here */}
          <Select
            selectedOption={selectedLicense}
            onChange={({ detail }) => setSelectedLicense(detail.selectedOption)}
            options={
              licenseQuery.data?.map((item) => ({
                label: item.product.name,
                value: item.uuid,
                // TODO add more here
                tags: item.assigned_user ? [item.assigned_user.email] : [],
              })) ?? []
            }
            empty="No licenses found"
            loadingText="Loading licenses..."
            filteringType="auto"
            statusType={
              licenseQuery.isError
                ? 'error'
                : licenseQuery.isLoading || licenseQuery.isPending || licenseQuery.isFetching
                  ? 'loading'
                  : undefined
            }
          />
        </FormField>
        <FormField description="The user for which the license will be assigned" label="User">
          {/* TODO add link to create users here */}
          <Select
            selectedOption={selectedUser}
            onChange={({ detail }) => setSelectedUser(detail.selectedOption)}
            options={
              userQuery.data
                ? [
                    ...userQuery.data.map((item) => ({
                      label: `${item.email}${item.name ? ` (${item.name})` : ''}`,
                      value: item.email,
                    })),
                    unassignOption,
                  ]
                : []
            }
            empty="No users found"
            loadingText="Loading users..."
            filteringType="auto"
            statusType={
              userQuery.isError
                ? 'error'
                : userQuery.isLoading || userQuery.isPending || userQuery.isFetching
                  ? 'loading'
                  : undefined
            }
          />
        </FormField>
        {create.error && <ErrorMessage loading={create.isPending} error={create.error} />}
      </SpaceBetween>
    </Modal>
  );
}
