import {
  Container,
  Box,
  Header,
  Button,
  Grid,
  SpaceBetween,
  TextContent,
  Link,
} from '@cloudscape-design/components';
import { useNavigate } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import useWindowDimensions from '../services/window';

export default function View() {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  return (
    <ViewLayout
      breadcrumbs={[{ text: 'Home', href: '' }]}
      headerVariant="high-contrast"
      header={
        <Container
          media={{
            content: (
              <img
                alt="AI Escape Products Logo"
                src="https://cdn.aiescape.io/logo-assistant.svg"
                width="300px"
                height="auto"
                // style={{
                //   borderRadius: 12,
                // }}
              />
            ),
            // position: 'side',
            // width: '150px',
            position: width < 497 ? 'top' : 'side',
            width: width < 497 ? '100%' : '200px',
          }}
          header={
            <Header description={<b>Making Intelligence Less Artificial</b>} variant="h1">
              Welcome to <b>AI Escape</b>
            </Header>
          }
        >
          <Box variant="p">
            AI Escape is your one-stop shop for all things AI. Our products and services are
            designed to help you escape the ordinary and embrace the extraordinary with the power of
            AI.
          </Box>
        </Container>
      }
      contentType="dashboard"
    >
      <Grid
        gridDefinition={[
          {
            colspan: {
              default: 12,
              s: 6,
            },
          },
          {
            colspan: {
              default: 12,
              s: 6,
            },
          },
          {
            colspan: {
              default: 12,
              s: 6,
            },
          },
        ]}
      >
        <Container header={<Header variant="h2">About Us</Header>} fitHeight>
          <TextContent>
            <Box variant="p">
              At AI Escape, we specialize in integrating Artificial Intelligence into business
              processes to enhance productivity and drive innovation. Our range of products and
              services are designed to make intelligence <b>less artificial</b> and{' '}
              <i>more accessible</i>. Whether you're looking for an AI-powered personal assistant or
              need expert consulting services, we have AI solutions to supercharge your business.
            </Box>
            <Box variant="p">
              We believe that AI should be <i>intuitive</i>, <i>user-friendly</i>, and{' '}
              <i>seamlessly integrated</i> into your existing workflows. Our team of AI experts is
              dedicated to helping you harness the power of AI to achieve your business goals. Let
              us help you escape the ordinary and embrace the extraordinary with <b>AI Escape</b>.
            </Box>
          </TextContent>
        </Container>

        <Container header={<Header variant="h2">Our Products</Header>} fitHeight>
          <TextContent>
            <Box variant="p">
              We offer a variety of AI-powered products designed to meet the needs of businesses of
              all sizes. From personal assistants to advanced AI business integrations, we have the
              solutions to help your business thrive.
            </Box>
            <ul
              style={{
                listStyleType: 'disclosure-closed',
                paddingLeft: '1rem',
              }}
            >
              <li>
                <Box variant="p">
                  <Link
                    href="/services/973bbff8-8254-42ec-af53-df3345daa617"
                    fontSize="inherit"
                    variant="secondary"
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate('/services/973bbff8-8254-42ec-af53-df3345daa617');
                    }}
                  >
                    <strong>Escape Events</strong>
                  </Link>
                  : Bringing fun, collaboration, and competition to corporate events with AI-powered
                  escape rooms.
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <Link
                    href="/services/504fb0d0-e310-469f-af17-cc9aec860cdf"
                    fontSize="inherit"
                    variant="secondary"
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate('/services/504fb0d0-e310-469f-af17-cc9aec860cdf');
                    }}
                  >
                    <strong>Escape Consulting</strong>
                  </Link>
                  : Expert AI business consulting services to help integrate AI into your business
                  processes.
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <Link
                    href="/services/ac6fc022-d004-42d0-8cfa-b8a884e36475"
                    fontSize="inherit"
                    variant="secondary"
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate('/services/ac6fc022-d004-42d0-8cfa-b8a884e36475');
                    }}
                  >
                    <strong>Escape Assistant</strong>
                  </Link>
                  : AI-powered everyday assistant that manages your emails, calendar, and more.
                </Box>
              </li>
              <li>
                <Box variant="p">
                  <Link
                    href="/services/0eb41dc8-e341-4efd-a555-bc4bf2f5e907"
                    fontSize="inherit"
                    variant="secondary"
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate('/services/0eb41dc8-e341-4efd-a555-bc4bf2f5e907');
                    }}
                  >
                    <strong>Escape Rooms</strong>
                  </Link>
                  : Immersive AI-powered escape rooms for an exhilarating and mind-boggling
                  experience.
                </Box>
              </li>
            </ul>
            <Box textAlign="center">
              <Button variant="link" onClick={() => navigate('/services')}>
                Explore Our Products
              </Button>
            </Box>
          </TextContent>
        </Container>

        <Container header={<Header variant="h2">Contact Us</Header>} fitHeight>
          <TextContent>
            <Box variant="p">
              Have questions or need more information? Reach out to us today, and we'll be happy to
              assist you.
            </Box>
            <Box variant="p">
              Contact us at{' '}
              <Link href="mailto:contact@aiescape.io" fontSize="inherit" variant="secondary">
                contact@aiescape.io
              </Link>
            </Box>
          </TextContent>
        </Container>
      </Grid>
    </ViewLayout>
  );
}
