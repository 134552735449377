import axiosInstance from './axiosInstance';

import { LicensePurchase, LicensePurchaseResponse, ProductRead } from './types';

export async function purchaseProduct(userId: string, data: LicensePurchase) {
  const response = await axiosInstance.post(`/users/${userId}/licenses/purchase`, data);
  return response.data as LicensePurchaseResponse;
}

export async function getProducts() {
  const response = await axiosInstance.get(`/products`);
  return response.data as ProductRead[];
}
