import {
  Container,
  Box,
  Header,
  Button,
  Grid,
  SpaceBetween,
  Table,
  StatusIndicator,
  Pagination,
} from '@cloudscape-design/components';
import { useCollection } from '@cloudscape-design/collection-hooks';

import { useNavigate } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { getUserLicenses } from '../services/license';
import { ResourceError } from '../components/Loading';
import { LicenseUserRead, UserReadWithAssignedLicenses } from '../services/types';
import { getDomainUsers } from '../services/domains';
import { UserCreateModal } from '../components/UserCreateModal';

export default function View() {
  const navigate = useNavigate();
  const resourceName = 'Users';
  const endpointName = 'users';

  const [createOpen, setCreateOpen] = useState(false);

  const [selected, setSelected] = useState<UserReadWithAssignedLicenses[]>();

  const { user } = useAuth0();

  const pageSize = 10;

  const domain = user && user.email ? user?.email.split('@')[1] : undefined;

  const query = useQuery({
    queryKey: ['domains', domain, endpointName],
    enabled: !!user?.sub,
    queryFn: async () => (domain ? await getDomainUsers(domain) : undefined),
    // staleTime: 3_600_000, // 60 minutes
  });

  const { items, paginationProps } = useCollection(query.data ?? [], {
    pagination: { pageSize },
  });

  return (
    <ViewLayout breadcrumbs={[{ text: 'Users', href: '' }]} contentType="table">
      <Table
        {...paginationProps}
        pagination={<Pagination {...paginationProps} disabled={query.isFetching} />}
        header={
          <Header
            variant="awsui-h1-sticky"
            description="Users in your organization"
            actions={
              <SpaceBetween direction="horizontal" size="s">
                <Button
                  variant="primary"
                  onClick={() => {
                    setCreateOpen(true);
                  }}
                >
                  Add User
                </Button>
                {/* <Button
                  variant="normal"
                  disabled={!selected?.length}
                  onClick={() => {
                    // TODO re-assign selected single license
                  }}
                >
                  Modify
                </Button> */}
                <UserCreateModal
                  open={createOpen}
                  setOpen={setCreateOpen}
                  onComplete={() => query.refetch()}
                />
              </SpaceBetween>
            }
            counter={query.data?.length ? `(${query.data.length})` : undefined}
          >
            {resourceName}
          </Header>
        }
        items={items}
        loading={query.isFetching}
        loadingText={`Loading ${resourceName}...`}
        variant="full-page"
        stickyHeader
        // selectedItems={selected}
        // onSelectionChange={({ detail }) => {
        //   setSelected(detail.selectedItems);
        // }}
        // selectionType='single'
        empty={
          <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
            {query.error ? (
              <ResourceError
                error={query.error}
                refetch={query.refetch}
                resourceName={resourceName}
              />
            ) : (
              <SpaceBetween size="xxs">
                <div>
                  <b>No {resourceName.toLowerCase()}</b>
                  <Box color="inherit" variant="p">
                    No {resourceName.toLowerCase()} found.
                  </Box>
                </div>
              </SpaceBetween>
            )}
          </Box>
        }
        columnDefinitions={[
          {
            id: 'email',
            header: 'Email',
            // TODO link to user page
            cell: (item) => item.email,
          },
          {
            id: 'name',
            header: 'Name',
            cell: (item) => (item.name ? item.name : '-'),
          },
          {
            id: 'licenses',
            header: 'Licenses',
            // TODO link each license to license page
            cell: (item) =>
              item.assigned_licenses.length > 0 ? (
                <SpaceBetween direction="vertical" size="xxs">
                  {item.assigned_licenses.map((license) => (
                    <StatusIndicator
                      key={license.uuid}
                      type={
                        license.status === 'pending'
                          ? 'pending'
                          : license.status === 'active'
                            ? 'success'
                            : 'error'
                      }
                    >
                      {license.product.name}
                    </StatusIndicator>
                  ))}
                </SpaceBetween>
              ) : (
                '-'
              ),
          },
          // {
          //   id: 'created',
          //   header: 'Created',
          //   cell: (item) => new Date(item.created_at + 'Z').toLocaleString(),
          // },
        ]}
      />
    </ViewLayout>
  );
}
