import {
  Container,
  Box,
  Header,
  Button,
  Grid,
  SpaceBetween,
  Cards,
  Link,
  ColumnLayout,
} from '@cloudscape-design/components';

import { useNavigate } from 'react-router-dom';
import { ViewLayout } from '../components/Layout';
import { getServices } from '../services/services';
import { useQuery } from '@tanstack/react-query';
import { LoadingOrError, ResourceError } from '../components/Loading';

export default function View() {
  const navigate = useNavigate();
  const resourceName = 'Services';
  const endpointName = 'services';

  const query = useQuery({
    queryKey: [endpointName],
    queryFn: async () => await getServices(),
    staleTime: 3_600_000,
  });

  return (
    <ViewLayout breadcrumbs={[{ text: 'Services', href: '' }]} contentType="cards">
      <Cards
        cardsPerRow={[
          {
            cards: 1,
            minWidth: 0,
          },
          {
            cards: 2,
            minWidth: 689,
          },
          {
            cards: 3,
            minWidth: 1024,
          },
        ]}
        cardDefinition={{
          header: (item) => (
            <Header
              variant="h2"
              actions={
                <SpaceBetween direction="horizontal" size="s">
                  <Button
                    variant="primary"
                    onClick={() => {
                      navigate(`/services/${item.uuid}`);
                    }}
                  >
                    View
                  </Button>
                </SpaceBetween>
              }
            >
              <Link
                fontSize="heading-m"
                href={`/services/${item.uuid}`}
                variant="secondary"
                onFollow={(e) => {
                  e.preventDefault();
                  navigate(`/services/${item.uuid}`);
                }}
              >
                {item.name}
              </Link>
            </Header>
          ),
          sections: [
            {
              content: (item) => {
                const caption = item.name;
                return (
                  <Link
                    href={`/services/${item.uuid}`}
                    variant="info"
                    onFollow={(e) => {
                      e.preventDefault();
                      navigate(`/services/${item.uuid}`);
                    }}
                  >
                    <img
                      alt={caption}
                      src={item.image_url}
                      width="100%"
                      height="auto"
                      style={{
                        borderRadius: 12,
                      }}
                    />
                  </Link>
                );
              },
              id: 'image',
            },
            {
              id: 'description',
              content: (item) => (
                <SpaceBetween direction="vertical" size="xs">
                  {item.description ?? <Box variant="p">{item.description}</Box>}
                </SpaceBetween>
              ),
            },
          ],
        }}
        header={
          <Header
            variant="awsui-h1-sticky"
            description="AI Escape Services"
            counter={query.data?.length ? `(${query.data.length})` : undefined}
          >
            {resourceName}
          </Header>
        }
        items={query.data ?? []}
        loading={query.isFetching}
        loadingText={`Loading ${resourceName}...`}
        // https://cloudscape.design/components/property-filter/?tabId=playground
        variant="full-page"
        stickyHeader
        empty={
          <Box color="inherit" margin={{ vertical: 'xs' }} textAlign="center">
            {query.error ? (
              <ResourceError
                error={query.error}
                refetch={query.refetch}
                resourceName={resourceName}
              />
            ) : (
              <SpaceBetween size="xxs">
                <div>
                  <b>No {resourceName.toLowerCase()}</b>
                  <Box color="inherit" variant="p">
                    No {resourceName.toLowerCase()} found.
                  </Box>
                </div>
              </SpaceBetween>
            )}
          </Box>
        }
      />
    </ViewLayout>
  );
}
