import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter } from 'react-router-dom';
import enMessages from '@cloudscape-design/components/i18n/messages/all.en';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import queryString from 'query-string';

import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { liveApiUrl, useAxiosInterceptors } from './services/axiosInstance';
import { LocalTopNavigation } from './components/Navigation';
import { AuthRequired } from './components/AuthRequired';
import HomePage from './pages/HomePage';
import ErrorPage from './pages/ErrorPage';
import ServicesPage from './pages/ServicesPage';
import ServicePage from './pages/ServicePage';
import LicensesPage from './pages/LicensesPage';
import UsersPage from './pages/UsersPage';
import LicensePurchasePage from './pages/LicensePurchasePage';
import * as Sentry from '@sentry/react';

import Auth0ProviderWithHistory from './context/AuthContext';
import { NavigationContext } from './context/Navigation';

import '@cloudscape-design/global-styles/index.css';
import './global.css';
import { ScrollToTop } from './components/ScrollToTop';
import { useAuth0 } from '@auth0/auth0-react';
import { getErrorDetails } from './components/Loading';

Sentry.init({
  dsn: 'https://ac41e83495472b0dd3a94ff217461d85@o4507625924722688.ingest.us.sentry.io/4507627049910272',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', liveApiUrl],
  // Session Replay
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.NODE_ENV === 'development' ? 'development' : 'production',
});

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error, query) => {
      const { title, description } = getErrorDetails(error);
      // Report the error to Sentry
      Sentry.captureException(error, {
        tags: {
          queryKey: `${query.queryKey}`,
          title,
          description,
        },
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (error) => {
      const { title, description } = getErrorDetails(error);
      // Report the error to Sentry
      Sentry.captureException(error, {
        tags: {
          title,
          description,
        },
      });
    },
  }),
});

function NavRoutes() {
  useAxiosInterceptors();

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated || !user) {
      return;
    }
    const sentryUserDetails = {
      id: user?.sub,
      email: user?.email,
      name: user?.name,
    };
    Sentry.setUser(sentryUserDetails);
  }, [isAuthenticated, user]);

  return (
    <>
      <ScrollToTop />
      <LocalTopNavigation />
      <Sentry.ErrorBoundary
        fallback={
          // TODO: Add a custom error page
          <ErrorPage />
        }
      >
        <Routes>
          <Route element={<HomePage />} path="/" />
          <Route path="/services">
            <Route element={<ServicesPage />} index />
            <Route element={<ServicePage />} path=":serviceId" />
          </Route>
          <Route path="/licenses">
            <Route
              element={
                <AuthRequired>
                  <LicensesPage />
                </AuthRequired>
              }
              index
            />
            <Route
              element={
                <AuthRequired>
                  <LicensePurchasePage />
                </AuthRequired>
              }
              path="purchase/success"
            />
          </Route>
          <Route
            element={
              <AuthRequired>
                <UsersPage />
              </AuthRequired>
            }
            path="/users"
          />
          <Route element={<ErrorPage />} path="*" />
        </Routes>
      </Sentry.ErrorBoundary>
    </>
  );
}

function App() {
  const { innerWidth: width } = window;
  const [navigationOpen, setNavigationOpen] = useState(width > 688);
  const navigationSize = 200;

  return (
    <BrowserRouter>
      <Auth0ProviderWithHistory>
        <QueryParamProvider
          adapter={ReactRouter6Adapter}
          options={{
            searchStringToObject: queryString.parse,
            objectToSearchString: queryString.stringify,
            removeDefaultsFromUrl: true,
          }}
        >
          <I18nProvider locale="en" messages={[enMessages]}>
            <QueryClientProvider client={queryClient}>
              <NavigationContext.Provider
                value={{ navigationOpen, setNavigationOpen, navigationSize }}
              >
                <NavRoutes />
              </NavigationContext.Provider>
            </QueryClientProvider>
          </I18nProvider>
        </QueryParamProvider>
      </Auth0ProviderWithHistory>
    </BrowserRouter>
  );
}

export default App;
