import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';

const useLive = false;

export const liveApiUrl = 'https://products-api.aiescape.io';

export const apiUrl =
  (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') && !useLive
    ? 'http://localhost:80'
    : liveApiUrl;

const axiosInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const useAxiosInterceptors = () => {
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();

  useEffect(() => {
    const setAuthInterceptor = async () => {
      if (isAuthenticated && !isLoading) {
        axiosInstance.interceptors.request.use(async (config) => {
          try {
            const token = await getAccessTokenSilently();

            if (token) {
              config.headers.Authorization = `Bearer ${token}`;
            }
          } catch (error) {
            console.error('Error getting access token:', error);
          }

          return config;
        });
      }
    };

    setAuthInterceptor();
  }, [getAccessTokenSilently, isAuthenticated, isLoading]);
};

export default axiosInstance;
